import React from 'react';

import { CategoryTile } from '@hpstellar/core/visId';

import useUserData, { getPStoreID } from '../../hooks/useUserData';
import { getSearchAutoClickGtmAttributes } from '../../metrics/metrics-helpers';
import { Helpers } from '../../core';
import { getSrpLinkData } from '../util';

import './css/search-support-tiles.less';

const getExtraProps = (tile, pStoreID) => {
    const { gtmActions, onClick } = tile || {};
    const extraProps = {};
    if(onClick){
        extraProps.onClick = onClick;
    }

    if(gtmActions){
        extraProps['data-gtm-category'] = gtmActions['data-gtm-category'];
        extraProps['data-gtm-id'] = gtmActions['data-gtm-id'];
        extraProps['data-gtm-value'] = gtmActions['data-gtm-value'];
        gtmActions['data-gtm-action'] && (extraProps['data-gtm-action'] = gtmActions['data-gtm-action']);
    }

    return extraProps;
}

export default props => {
    const { supportTiles, className, isAutocomplete } = props || {};
    const { userData } = useUserData();
    const pStoreID = getPStoreID(userData);

    return supportTiles && supportTiles.length > 0 && (
        <div className={`srp-faq-tiles${className ? ' '+className : ''}`}>
            {supportTiles.reduce((tiles, tile, idx) => {
                const { title, url, className } = tile || {};
                const { to, target } = getSrpLinkData(url, pStoreID, 'support') || {};// typeof pStoreID === 'string' ? Helpers.mergeQueryStrings(url, `?pStoreID=${pStoreID}`) : url;
                if(tile){
                    tiles.push(
                        <CategoryTile
                            key={idx} 
                            title={title}
                            variant='minimal'
                            titleCentered
                            to={to}
                            {...target ? { target } : {}}
                            className={`search-support-tile${className ? ' ' + className : ''}`}
                            {...getExtraProps(tile, isAutocomplete ? pStoreID : null)}
                        />
                    );
                }
                return tiles;
            }, [])}
        </div>
    );
}