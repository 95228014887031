import React from 'react';
import { Typography } from '@hpstellar/core';
import VisIdTypography from '@hpstellar/core/visId/Typography';
import Link from '@hpstellar/core/visId/Link';
import { getSearchAutoClickGtmAttributes } from '../../metrics/metrics-helpers';

import './css/search-suggestion-section-header.less'

export default ({ count, seeAllLink, title, closeModalAndInputBar, searchKey, sectionTitleTypographyProps }) => {
    const hasCount = count && count > 0;
    const className = `search-content-header${sectionTitleTypographyProps ? '-tk' : ''}${hasCount ? '' : ' search-content-header-no-count'}`;
    const TypographyComponent = sectionTitleTypographyProps ? VisIdTypography : Typography;
    const typographyComponentProps = sectionTitleTypographyProps ? { ...sectionTitleTypographyProps, className } : { tag: 'h1', variant: 'titleS', className };
    const linkProps = {
        to: seeAllLink,
        ...sectionTitleTypographyProps ? { variant: 'bodyS'} : { variation: 'body', size: 'small' },
        className: 'search-content-header-link',
        ...getSearchAutoClickGtmAttributes('see-all-results', searchKey, title)
    }
    
    if(typeof closeModalAndInputBar === 'function') { 
        linkProps.onClickCapture = closeModalAndInputBar;
    }

    return (
        <div className={`search-suggestion-header-section${sectionTitleTypographyProps ? ' search-suggestion-header-section-tk' : ''}`}>
            <TypographyComponent {...typographyComponentProps}>{title}</TypographyComponent>
            {
                hasCount && (
                    <Typography 
                        tag='h1'
                        variant='bodyS'
                        className='search-content-header-count'>{`(${count})`}</Typography>
                )
            }
            {
                seeAllLink && (
                    <Link {...linkProps}>
                        See all
                    </Link>
                )
            }
        </div>
    );
}