import React from 'react';
import { useSelector } from 'react-redux';

import { Grid, PriceBlock } from '@hpstellar/core';
import { CategoryTile } from '@hpstellar/core/visId';
import { useWindowSize } from '@hpstellar/core/hooks';

import { FAQ_AUTOCOMPLETE_TILES, getFaqAutocompleteTiles } from '../util';
import SearchSupportTiles from './search-support-tiles';
import SearchSuggestionSectionHeader from './search-suggestion-section-header';

import useUserData, { getPStoreID } from '../../hooks/useUserData';
import { getSearchAutoClickGtmAttributes } from '../../metrics/metrics-helpers';
import { Helpers } from '../../core';

import './css/search-suggestions-content.less';

const getSearchTiles = data => {
    const { 
        list,
        variant,
        prices, 
        title,
        searchKey,
        name,
        closeModalAndInputBar,
        trackSearchAutoClick,
        isMobileProductTile,
        type,
        isMobile,
        limit,
        pStoreID
    } = data || {}
    return Array.isArray(list) && (
        list.reduce((allItems, item, idx) => {
            try {
                if(typeof limit === 'number' && allItems.length >= limit){
                    return allItems;
                }
                let { Document } = item.Results;
                if (!Document) return null;

                let [hsSalePrice] = (Document && Document.sale_price) || [];
                let [hsListPrice] = (Document && Document.list_price) || [];

                let sku = Document.sku && Document.sku[0];
                let name = Document.product_name && Document.product_name[0];
                let thumbnail = Document.thumbnailimage && Document.thumbnailimage[0];
                let url =
                    Document.pdp_url &&
                    typeof Document.pdp_url[0] === 'string' &&
                    Document.pdp_url[0].substring(Document.pdp_url[0].indexOf(process.env.BASENAME));
                let description = Document.description && typeof Document.description[0] === 'string' && Document.description;
                let price = prices && prices[sku];

                // if no price from HPServices, show the price from hawksearch
                if (!price || (!price.salePrice && !price.listPrice)) {
                    price = { regularPrice: hsListPrice, salePrice: hsSalePrice };
                }

                let { priceDifference } = price || {};
                let validPstoreID = typeof pStoreID === 'string';
                let commonProps = {
                    title: name,
                    to: validPstoreID ? Helpers.mergeQueryStrings(url, `?pStoreID=${pStoreID}`) : url ,
                    image: {
                        src: thumbnail
                    },
                    onClick: closeModalAndInputBar,
                    className: 'ac-tile',
                    ...getSearchAutoClickGtmAttributes(name, searchKey, title)
                }
                let uniqueProps = type === 'product' ? ({
                    footer: (
                        <PriceBlock 
                            {...(price || {})}
                            // TODO: Check if needed. The props below are passed to PriceBlock from the previous MiniProductTile
                            wrapPriceDiff
                            // style={price.bundle ? 'left' : undefined}
                            style='left'
                            size='small'
                            hideDisclaimer={isMobileProductTile}
                        />
                    ),
                    className: `search-suggestion-product-tile${
                        priceDifference ? ' with-price-difference' : ''
                    } ac-tile`,
                    variant: 'product',
                }) : {
                    description,
                    className: 'search-suggestion-content-tile ac-tile',
                    variant: isMobile ? 'product' : 'primary',
                    ...validPstoreID ? { target: '_blank' } : {}
                };

                if (Document) {
                    allItems.push(
                        <CategoryTile
                            key={idx}
                            {...commonProps}
                            {...uniqueProps}
                        />
                    );
                }
            } catch (e) {}

            return allItems;
        }, [])
    )
};

const SearchSuggestionsContent = ({
    prices,
    Products,
    ProductHeading,
    ProductCount,
    Content,
    ContentHeading,
    ContentCount,
    searchKey,
    trackSearchAutoClick,
    closeModalAndInputBar,
    getAutocompleteLinkData,
    seeAllLinkKeyword,
    support,
    sectionTitleTypographyProps
}) => {
    const { innerWidth } = useWindowSize();
    const { userData } = useUserData();
    const pStoreID = getPStoreID(userData);
    const isMobileProductTile = useSelector(state => state && state.ui && state.ui.width && state.ui.width <= 1275);
    const exploreSectionTitle = ContentHeading || 'Explore';
    const isMobile = innerWidth < 1280;

    const contentTiles = getSearchTiles({
        list: Content,
        variant: 'primary',
        prices, 
        title: exploreSectionTitle,
        searchKey,
        closeModalAndInputBar,
        trackSearchAutoClick,
        isMobileProductTile,
        isMobile,
        limit: 3,
        pStoreID
    })

    const productSectionTitle = ProductHeading || 'Shop';
    const commonContentTileClass = 'ac-row';

    const { productSrpLink, contentSrpLink, supportSrpLink } = getAutocompleteLinkData(searchKey, pStoreID, seeAllLinkKeyword)
    const productTiles = getSearchTiles({
        list: Products,
        variant: 'product',
        prices, 
        title: productSectionTitle,
        searchKey,
        closeModalAndInputBar,
        trackSearchAutoClick,
        isMobileProductTile,
        type: 'product',
        isMobile,
        pStoreID
    })
    const commonProps = {
        closeModalAndInputBar,
        searchKey,
        sectionTitleTypographyProps
    }
    const supportSectionTitle = 'Support';
    const supportTiles = getFaqAutocompleteTiles(support, null, supportSectionTitle, searchKey, getSearchAutoClickGtmAttributes, null, true);
    return (
        <div className="search-suggestions-content">
            {productTiles && productTiles.length > 0 && (
                <div className="search-suggestion-products-area">
                    <SearchSuggestionSectionHeader seeAllLink={productSrpLink} title={productSectionTitle} count={ProductCount} {...commonProps}/>
                    <div className={`search-suggestion-product-tiles-container ${commonContentTileClass}`} container xs={12}>
                        {productTiles}
                    </div>
                </div>
            )}
            {contentTiles && contentTiles.length > 0 && (
                <div className='search-suggestion-content-area'>
                    <SearchSuggestionSectionHeader seeAllLink={contentSrpLink} title={exploreSectionTitle} count={ContentCount} {...commonProps}/>
                    <div className={`suggestion-store-support-category-tiles suggestion-content-category-tiles ${commonContentTileClass}`} container xs={12}>
                        {contentTiles}
                    </div>
                </div>
            )}
            {
                supportTiles && supportTiles.tiles  && supportTiles.tiles.length > 0 && (
                    <div className='search-suggestion-support-area'>
                        <SearchSuggestionSectionHeader seeAllLink={supportSrpLink} title={supportSectionTitle} {...commonProps} />
                        <SearchSupportTiles
                            supportTiles={supportTiles.tiles}
                            className={`${FAQ_AUTOCOMPLETE_TILES.className} suggestion-store-support-category-tiles srp-faq-tiles ${commonContentTileClass}`}
                            isAutocomplete
                        />
                    </div>
                )
            }
        </div>
    );
};

export default SearchSuggestionsContent;
