import React from 'react';

import {Grid} from '@hpstellar/core';

import SearchSuggestionsList, {SearchSuggestionsListRow} from './search-suggestions-list';

import './css/search-suggestions-rail.less';

export default ({
    Categories,
    CategoryHeading,
    Popular,
    PopularHeading,
    viewButton,
    device,
    autocompleteTrendingKeywordTitleTypographyProps,
    ...rest
}) => {

    const linkProps = {
        className: 'search-suggestion-link',
    }

    const rowProps = {
        className: 'srp-popular-searches-row'
    };

    const headerRowProps = {
        className: 'srp-popular-searches-row srp-popular-searches-header-row'
    };

    const colProps = {
        className: 'search-suggestion-popular-searches-column',
        xl: 12,
        width: 12
    };

    const typographyProps = {
        tag: 'h1',
        variant: 'headingTitleSmall',
        className: `search-suggestion-header${autocompleteTrendingKeywordTitleTypographyProps ? '-tk' : ''}`
    };



    const listProps = {
        rowProps,
        headerRowProps,
        colProps,
        typographyProps,
        linkProps
    };

    const hasCategories = Array.isArray(Categories) && Categories.length > 0; 

    return (
        <Grid className='search-suggestions-rail' col xs={12}>
            <SearchSuggestionsList
                {...listProps}
                heading='Suggestions'
                defaultHeading='Suggestions'
                items={Popular}
                className={`srp-popular-searches'${!hasCategories ? ' srp-column-list-last' : ''}`}
                autocompleteTrendingKeywordTitleTypographyProps={autocompleteTrendingKeywordTitleTypographyProps}
                {...rest}
            />
            <SearchSuggestionsListRow rowProps={rowProps} colProps={colProps}>
                { viewButton }
            </SearchSuggestionsListRow>
        </Grid>
    )
}