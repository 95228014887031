import React, { useRef, useCallback } from 'react';

import {Grid,Typography} from '@hpstellar/core';
import VisIdTypography from '@hpstellar/core/visId/Typography';
import Button from '@hpstellar/core/visId/Button';

import { Helpers } from '../../core/src/helpers';
import { getSearchAutoClickGtmAttributes, memoizedFormatMetricValue } from '../../metrics/metrics-helpers';

import './css/search-suggestions-list.less';


export const SearchSuggestionsListRow = ({ rowProps, colProps, children }) => (
    <Grid {...rowProps} col>
        {children}
    </Grid>
)

const SearchSuggestionsLink = ({ Url, Value, RawValue, rowProps, colProps, linkProps, parseUrl, onLinkHover, previewKeyword, onClickTrack, closeModalAndInputBar, setInitialSearchId, searchKey }) => {
    if(!Url || !Value){
        return null;
    }
    let parsedUrl = parseUrl ? parseUrl(Url) : `${process.env.BASENAME}/sitesearch?keyword=${Url.split('keyword=').pop()}`;
    let ref = useRef();
    let plainKeywordText = ref && ref.current && ref.current.textContent; // needed because the keywords from HS have HTML on them.
    const onMouseOver = () => {
        try{
            if(plainKeywordText) onLinkHover(plainKeywordText);
        }catch(e){}
    }

    const onClick = useCallback(() => {
        onClickTrack && onClickTrack();
        closeModalAndInputBar && closeModalAndInputBar();
        setInitialSearchId && setInitialSearchId(parsedUrl);
    }, [closeModalAndInputBar, onClickTrack, setInitialSearchId])
    const metricGtmValue = typeof RawValue === 'string' ? decodeURIComponent(RawValue) : RawValue;
    const gtmAttributes = getSearchAutoClickGtmAttributes(metricGtmValue, searchKey, 'top-suggestions');
    return (
        <SearchSuggestionsListRow rowProps={rowProps} colProps={colProps}>
            <span onClick={onClick}>
                <Button 
                    {...linkProps} 
                    {...gtmAttributes} 
                    className={`${linkProps.className || ''}
                    ${plainKeywordText && plainKeywordText === previewKeyword ? ' selected-preview-keyword' : ''}`} 
                    to={parsedUrl}
                    theme='dark'
                    variation='tertiary'
                    onMouseOver={onMouseOver}
                >
                    <span ref={ref} className='search-autocomplete-link-text' dangerouslySetInnerHTML={Helpers.createMarkup(Value)} />
                </Button>
            </span>
        </SearchSuggestionsListRow>
    );
}

const SearchSuggestionsHeader = ({ 
    rowProps, 
    colProps, 
    typographyProps, 
    heading, 
    defaultHeading,
    autocompleteTrendingKeywordTitleTypographyProps
}) => {
    const { isVisId } = autocompleteTrendingKeywordTitleTypographyProps || {};
    const TypographyComponent = isVisId ? VisIdTypography : Typography;
    const titleTypographyProps = isVisId && autocompleteTrendingKeywordTitleTypographyProps && autocompleteTrendingKeywordTitleTypographyProps.typographyProps ? autocompleteTrendingKeywordTitleTypographyProps.typographyProps : typographyProps;
    return heading || defaultHeading ? (
        <SearchSuggestionsListRow rowProps={rowProps} colProps={colProps}>
            <TypographyComponent {...titleTypographyProps}>{heading || defaultHeading}</TypographyComponent>
        </SearchSuggestionsListRow>
    ) : null;
}

const SearchSuggestionsList = ({
    rowProps,
    headerRowProps,
    colProps, 
    typographyProps, 
    linkProps,
    heading,
    defaultHeading,
    items,
    parseUrl,
    className,
    trackSearchAutoClick,
    searchKey,
    autocompleteTrendingKeywordTitleTypographyProps,
    ...rest
}) => (
    items instanceof Array && items.length > 0 ? (
        <Grid className={`srp-column-list${className ? ' '+className : ''}`} container xs={12}>
            <SearchSuggestionsHeader 
                colProps={colProps}
                rowProps={headerRowProps}
                heading={heading}
                typographyProps={typographyProps}
                defaultHeading={defaultHeading}
                autocompleteTrendingKeywordTitleTypographyProps={autocompleteTrendingKeywordTitleTypographyProps}
            />
            {
                items.map( (item, idx) => {
                    let {Url, Value, RawValue} = item || {};
                    let linkRowProps = idx === 0
                                        ? { 
                                            ...rowProps, 
                                            className: `srp-column-first-row${ rowProps && rowProps.className ? ' '+rowProps.className : ''}`
                                          }
                                        : rowProps;
                    let sectionTitle = heading;

                    return (
                        <SearchSuggestionsLink
                            colProps={colProps}
                            rowProps={linkRowProps}
                            key={idx} 
                            Url={Url}
                            Value={Value}
                            RawValue={RawValue}
                            linkProps={linkProps}
                            parseUrl={parseUrl}
                            searchKey={searchKey}
                            {...rest}
                        />
                    )
                })
            }
        </Grid>
    ) : null
)

export default SearchSuggestionsList;